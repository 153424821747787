<template>
  <div class="main-container">
    <v-toolbar flat tile class="box" style="background-color: transparent">
      <v-btn icon @click="$router.go(-1)">
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>
      <v-toolbar-title class="pl-0"> ข้อมูลค่าคอมมิชชั่น</v-toolbar-title>
    </v-toolbar>

    <Loading v-if="loading"/>

    <div class="mx-4 box box-2" style="min-height: 100%">
      <v-row
          dense
          class="box box-2"
          style="height: 100%; display: flex; flex-direction: row"
      >
        <v-col style="height: 100%">
          <v-card
              style="height: 100%; overflow: auto"
              class="rounded-lg elevation-2"
              outlined
              tile
          >
            <v-row class="mx-2 mt-2">
              <v-col class="font-weight-bold">ชื่อ</v-col>
              <v-col>{{ user.first_name }} {{ user.last_name }}</v-col>
              <v-col class="font-weight-bold">เบอร์โทร</v-col>
              <v-col>{{ user.tel }}</v-col>
            </v-row>
            <v-row class="mx-2 mt-2">
              <v-col class="font-weight-bold">ตำแหน่ง</v-col>
              <v-col>{{
                  user.position ? user.position.name : "ไม่ระบุตำแหน่ง"
                }}
              </v-col>
              <v-col class="font-weight-bold"></v-col>
              <v-col></v-col>
            </v-row>
            <v-divider class="mt-4"/>
            <template v-if="view_commission_other">
              <v-row class="mx-5 mt-4"
              ><span class="font-weight-bold">รายการคอมมิชั่นที่ค้างจ่าย</span>

                <v-btn
                    @click="
                  $router.push({
                    path: `/salary/commission/${$route.params.id}`,
                  })
                "
                    depressed
                    small
                    color="primary"
                    class="ml-2"
                >
                  <v-icon left>mdi-eye</v-icon>
                  ดูรายการคอมมิชชั่นทั้งหมด
                </v-btn>

                <v-btn
                    @click="
                  $router.push({ path: `/salary/history/${$route.params.id}` })
                "
                    depressed
                    small
                    color="amber"
                    class="ml-2"
                >
                  <v-icon left>mdi-eye</v-icon>
                  ดูประวัติการจ่ายค่าคอมมิชชั่น
                </v-btn>
              </v-row>
              <v-row class="px-5" justify="space-between">
                <v-col cols="12" md="6">
                  ดูตามวันที่
                  <DateRangeSelector
                      accept-string="ดูค่าคอมมิชชั่น"
                      :backwards="2"
                      v-model="dates_for_view_row"
                      @accept="getCommission()"
                  />
                </v-col>
                <v-col cols="12" md="6">
                  เลือกจ่ายค่าคอมฯ ตามวันที่
                  <DateRangeSelector
                      accept-string="เลือกค่าคอมมิชชั่น"
                      :backwards="2"
                      v-model="dates_for_select_row"
                      @accept="selectionByDate()"
                  />
                </v-col>
                <v-col cols="12" md="6">
                  <v-radio-group
                      v-model="select_mode"
                      @change="selectMode($event)"
                  >
                    <v-radio
                        label="เลือกเฉพาะ order ที่ชำระเงินแล้ว"
                        :value="0"
                    ></v-radio>
                    <v-radio label="เลือกทั้งหมด" :value="1"></v-radio
                    >
                  </v-radio-group>
                </v-col>
              </v-row>
              <v-data-table
                  class="mt-6 mx-0"
                  :headers="headers"
                  :items="selected"
                  hide-default-footer
                  :items-per-page="-1"
                  @click:row="clickRow"
              >
                <template v-slot:item="{ item }">
                  <tr
                      :class="
                    selectedRows.indexOf(item) > -1 ? 'blue lighten-5' : ''
                  "
                      @click="clickRow(item)"
                  >
                    <td>{{ item.date }}</td>
                    <td>{{ showName(item) }}</td>
                    <td class="text-end" style="width: 150px">{{ $currency(item.price).format() }} บาท</td>
                    <td @click.stop="null" style="width: 200px">
                      <router-link :to="goRef(item).link" target="_blank">{{
                          goRef(item).text
                        }}
                      </router-link>
                    </td>
                    <td>
                      <v-btn text
                             dark
                             color="green"
                             v-if="item.order_payment_status === 'ชำระแล้ว'"
                      >ลูกค้า{{ item.order_payment_status || "" }}
                      </v-btn
                      >
                      <v-btn text
                             dark
                             color="red"
                             v-else-if="item.order_payment_status === 'ยังไม่ชำระ'"
                      >ลูกค้า{{ item.order_payment_status || "" }}
                      </v-btn
                      >
                      <v-btn text
                             dark
                             color="grey"
                             v-else-if="item.order_payment_status === 'ยกเลิกออเดอร์'"
                      >ลูกค้า{{ item.order_payment_status || "" }}
                      </v-btn
                      >
                    </td>
                    <td>
                      <v-checkbox
                          :input-value="selectedRows.indexOf(item) > -1"
                      ></v-checkbox>
                    </td>
                  </tr>
                </template>
              </v-data-table>

              <v-col cols="12" md="10" lg="8" xl="6" class="mt-6">
                <v-row>
                  <v-col cols="4" align-self="center"> จำนวนเงินที่เพิ่ม</v-col>
                  <v-col>
                    <v-text-field
                        type="number"
                        v-model="add"
                        outlined
                        style="width: 200px"
                        height="32px"
                        hide-details
                        suffix="บาท"
                    ></v-text-field>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="4" align-self="center"> จำนวนเงินที่หัก</v-col>
                  <v-col>
                    <v-text-field
                        type="number"
                        v-model="minus"
                        outlined
                        style="width: 200px"
                        height="32px"
                        hide-details
                        suffix="บาท"
                    ></v-text-field>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="4" align-self="center"> หมายเหตุ</v-col>
                  <v-col>
                    <v-textarea
                        v-model="remark"
                        outlined
                        hide-details
                        rows="3"
                    ></v-textarea>
                  </v-col>
                </v-row>

                <v-row style="font-size: 1.25rem">
                  <v-col cols="4" align-self="center"> รวมค่าคอมมิชชั่น</v-col>
                  <v-col>
                    <b class="blue--text">{{ sumCommission.toFixed(2) }}</b> บาท
                  </v-col>
                </v-row>
              </v-col>

              <v-row class="mx-3 mb-6 mt-6">
                <v-btn @click="showConfirmDialog = true" color="primary"
                >จ่ายค่าคอมมิขชั่น
                </v-btn
                >
              </v-row>
            </template>

            <div
                v-if="
                $store.state.authUser.user_permissions.includes(
                  'view_permission'
                )
              "
            >
              <v-divider/>
              <PermissionControls :user="user"></PermissionControls>
            </div>
          </v-card>
        </v-col>
      </v-row>
    </div>
    <v-dialog v-model="showConfirmDialog" persistent max-width="512">
      <v-card>
        <v-card-title style="font-family: Kanit, serif !important">
          ยืนยันการจ่ายค่าคอมมิชชั่น
          <v-spacer></v-spacer>
          <v-btn @click="showConfirmDialog = false" icon>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text
        >ยืนยันการจ่ายค่าคอมมิชชั่นเป็นจำนวน
          {{ sumCommission.toFixed(2) }} บาท
        </v-card-text
        >
        <v-card-actions>
          <v-spacer/>
          <v-btn @click="showConfirmDialog = false" text>ยังไม่ใช่ตอนนี้</v-btn>
          <v-btn @click="paidCommission()" color="primary"
          >จ่ายค่าคอมมิชชั่น
          </v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from "axios";
import PermissionControls from "@/components/PermissionControls";
import DateRangeSelector from "@/components/DateRangeSelector";
import DateTime from "luxon/src/datetime.js";
import Loading from "@/components/Loading";

export default {
  name: "SalaryDetail",
  components: {Loading, DateRangeSelector, PermissionControls},
  data: () => ({
    selectedRows: [],
    headers: [
      {
        text: "วันที่",
        value: "date",
      },
      {
        text: "รายการ",
        value: "name",
      },
      {
        text: "ค่าคอมมิชชั่น",
        value: "price",
      },
      {
        text: "อ้างอิง",
        value: "ref",
      },
      {
        text: "การจ่ายเงิน",
        value: "order_payment_status",
      },
      {
        text: "เลือกจ่ายค่าคอมตอนนี้",
        value: "is_paid",
      },
    ],
    selected: [],
    showConfirmDialog: false,
    user: {},
    add: 0,
    minus: 0,
    remark: "",
    dates_for_select_row: [DateTime.now().minus({months: 2}).toFormat('yyyy-LL-dd'), DateTime.now().toFormat('yyyy-LL-dd')],
    dates_for_view_row: [DateTime.now().minus({months: 2}).toFormat('yyyy-LL-dd'), DateTime.now().toFormat('yyyy-LL-dd')],
    loading: true,
    select_mode: 0,
  }),
  async mounted() {
    await Promise.all([this.getCommission()]);
    this.selectMode(this.select_mode);
  },
  methods: {
    showName(item) {
      if (item.po !== null) {
        return "รับสินค้า";
      } else if (item.order !== null) {
        return "ออเดอร์";
      }
    },
    clickRow(item) {
      this.toggleSelection(item);
    },
    toggleSelection(item) {
      if (this.selectedRows.includes(item)) {
        this.selectedRows = this.selectedRows.filter(
            (selectedKeyID) => selectedKeyID.id !== item.id
        );
      } else {
        this.selectedRows.push(item);
      }
    },
    getCommission() {
      this.loading = true;
      return axios
          .get(`api/tracks/commission/${this.$route.params.id}/`, {
            params: {
              start_date: DateTime.fromFormat(
                  this.dates_for_view_row[0],
                  "yyyy-MM-dd"
              ).toFormat("yyyy/MM/dd"),
              end_date: DateTime.fromFormat(
                  this.dates_for_view_row[
                      this.dates_for_view_row.length === 1 ? 0 : 1
                      ],
                  "yyyy-MM-dd"
              ).toFormat("yyyy/MM/dd"),
            },
          })
          .then((res) => {
            this.selected = res.data.data.filter((c) => c.is_paid === false);
            this.selectedRows = this.selected;
            this.remark = "";
            this.add = 0;
            this.minus = 0;
            this.user = res.data.user
            this.dates_for_select_row = this.dates_for_view_row
            this.selectionByDate()
            this.selectMode(this.select_mode);
          })
          .catch((e) => {
            this.$store.state.snackbar = {
              text:
                  "เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง ข้อความที่ระบบแจ้ง : " +
                  e.response.status +
                  " " +
                  e.response.statusText,
              visible: true,
              color: "error",
            };
          })
          .finally(() => {
            this.loading = false;
          });
    },
    goRef(item) {
      if (item.order !== null) {
        return {
          link: "/order/" + item.order,
          text: "ดูออเดอร์ " + item.order_display,
        };
      } else if (item.po_id !== null) {
        return {
          link: "/stock/po/" + item.po,
          text: "ดู PO " + item.po,
        };
      }
    },
    selectionByDate() {
      this.selectedRows = this.selected.filter((c) => {
        return (
            DateTime.fromFormat(c.date, "yyyy/MM/dd").toFormat("yyyy-MM-dd") >=
            this.dates_for_select_row[0] &&
            DateTime.fromFormat(c.date, "yyyy/MM/dd").toFormat("yyyy-MM-dd") <=
            this.dates_for_select_row[1]
        );
      });
    },
    paidCommission() {
      let payload = {
        commissions: [],
        total_price: this.sumCommission.toFixed(2),
        add: this.add,
        minus: this.minus,
        remark: this.remark,
      };
      this.selectedRows.forEach((p) => {
        payload.commissions.push(p.id);
      });
      axios
          .put("/api/tracks/commission/", payload)
          .then(() => {
            // console.log(res.data.data)
            this.$store.state.snackbar = {
              text: "บันทึกการจ่ายค่าคอมมิชั่นสำเร็จ",
              visible: true,
              color: "success",
            };
            this.getCommission();
            this.showConfirmDialog = false;
          })
          .catch((e) => {
            this.$store.state.snackbar = {
              text:
                  "เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง ข้อความที่ระบบแจ้ง : " +
                  e.response.status +
                  " " +
                  e.response.statusText,
              visible: true,
              color: "error",
            };
          });
    },
    selectMode(val) {
      if (val === 0) {
        this.selectionByDate();
        this.selectedRows = this.selectedRows.filter((c) => {
          return c.order_payment_status === "ชำระแล้ว";
        });
      } else {
        this.selectionByDate();
      }
    },
    hasPermission(permission) {
      return this.$store.state.authUser.user_permissions.includes(permission)
    }
  },
  computed: {
    sumCommission() {
      return (
          this.selectedRows.reduce((accum, item) => accum + item.price, 0) +
          parseFloat(this.add || "0") -
          parseFloat(this.minus || "0")
      );
    },
    view_commission_other () {
      if (this.hasPermission('view_commission_other')) {
        return true
      } else {
        return !!(this.hasPermission('view_commission') &&
            this.$store.state.authUser.id === parseInt(this.$route.params.id));
      }
    }
  },
};
</script>

<style scoped>
</style>
