<template>
  <v-card elevation="0">
    <v-card-title>
      กำหนดสิทธิ์การเข้าถึง
    </v-card-title>
    <v-card-text>
      <v-treeview
          v-model="selection"
          selection-type="leaf"
          selectable
          selected-color="primary"
          :items="items"
          open-all
      ></v-treeview>
    </v-card-text>
    <v-card-actions>
      <v-btn @click="savePermission()" class="ml-2 mb-2" color="primary">
        บันทึกการแก้ไขสิทธิ์
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import axios from 'axios'

export default {
  props: {
    user: {
      type: Object,
      default: () => ({})
    }
  },
  name: "PermissionControls",
  data: () => ({
    items: [
      {
        id: 1,
        name: 'จัดการออเดอร์',
        children: [
          {id: 'add_order', name: 'สร้างออเดอร์'},
          {id: 'view_order', name: 'ดูออเดอร์'},
          {id: 'change_order', name: 'แก้ไขออเดอร์'},
          {id: 'delete_order', name: 'ยกเลิกออเดอร์ได้'},
          // {id: 'permanent_delete', name: 'ลบออเดอร์ที่ถูกยกเลิกได้'},
        ],
      },
      {
        id: 5,
        name: 'จัดการสินค้า',
        children: [
          {id: 'add_product', name: 'เพิ่มสินค้า'},
          {id: 'view_product', name: 'ดูสินค้า'},
          {id: 'change_product', name: 'แก้ไขสินค้า'},
          {id: 'delete_product', name: 'ลบสินค้า'},
          {id: 'add_stocklocation', name: 'สร้างสต๊อกสินค้า'},
          {id: 'change_stocklocation', name: 'แก้ไขข้อมูลสต็อกสินค้า/แก้ไขจำนวนสินค้าคงเหลือ'},
          {id: 'delete_stocklocation', name: 'ลบสต๊อกสินค้า'},
        ],
      },
      {
        id: 234,
        name: 'จัดการคำสั่งซื้อ',
        children: [
          {id: 'add_purchaseorder', name: 'เพิ่มใบสั่งซื้อสินค้า'},
          {id: 'view_purchaseorder', name: 'ดูใบสั่งซื้อ'},
          {id: 'change_purchaseorder', name: 'รับเข้าสินค้า'},
          {id: 'delete_purchaseorder', name: 'ลบใบสั่งซื้อสินค้า'},
        ],
      },
      {
        id: 15,
        name: 'จัดการการเติมน้ำมัน',
        children: [
          {id: 'add_oiltransaction', name: 'เพิ่มข้อมูลการเติมน้ำมัน'},
          {id: 'view_oiltransaction', name: 'ดูข้อมูลการเติมน้ำมัน'},
        ],
      },
      {
        id: 19,
        name: 'จัดการการซ่อมบำรุง',
        children: [
          {id: 'add_maintenance', name: 'สร้างการซ่อมบำรุง'},
          {id: 'view_maintenance', name: 'ดูการซ่อมบำรุง'},
          {id: 'change_maintenance', name: 'แก้ไขการซ่อมบำรุง'},
          {id: 'delete_maintenance', name: 'ลบการซ่อมบำรุง'},
        ],
      },
      // {
      //   id: 312,
      //   name: 'ดูค่าคอมมิชชั่น',
      //   children: [
      //     {id: 'view_commission', name: 'ดูค่าคอมมิชชั่น'},
      //   ],
      // },
      {
        id: 322,
        name: 'ดูค่าคอมมิชชั่น/การจัดการสิทธิ์',
        children: [
          {id: 'view_commission', name: 'ดูค่าคอมมิชชั่นเฉพาะของตัวเอง'},
          {id: 'view_commission_other', name: 'ดูค่าคอมมิชชั่นของบุคคลอื่นได้'},
          {id: 'view_permission', name: 'จัดการสิทธิ์/แก้ไขสิทธ์ ให้ User คนอื่นได้'},
        ],
      },
      {
        id: 121,
        name: 'ข้อมูลลูกค้า',
        children: [
          {id: 'view_customer', name: 'จัดการข้อมูลลูกค้าได้'},
        ],
      },
      // {
      //   id: 534,
      //   name: 'จัดการสิทธิ์',
      //   children: [
      //     {id: 'view_permission', name: 'จัดการสิทธิ์/แก้ไขสิทธ์ ให้ User คนอื่นได้'},
      //   ],
      // },
      // {
      //   name: 'ดูออเดอร์c',
      //   value: 'c',
      //   children: [
      //     {
      //       id: '3',
      //       name: 'ดูออเดอร์',
      //       value: 'd',
      //     },
      //     {
      //       id: '4',
      //       name: 'ดูออเดอร์',
      //       value: 'd',
      //     },
      //     {
      //       id: '5',
      //       name: 'ดูออเดอร์',
      //       value: 'd',
      //     }
      //   ]
      // },
      // {
      //   name: 'ดูออเดอร์c',
      //   value: 'c',
      //   children: [
      //     {
      //       id: '3',
      //       name: 'ดูออเดอร์',
      //       value: 'd',
      //     },
      //     {
      //       id: '4',
      //       name: 'ดูออเดอร์',
      //       value: 'd',
      //     },
      //     {
      //       id: '5',
      //       name: 'ดูออเดอร์',
      //       value: 'd',
      //     }
      //   ]
      // },
    ],
    selection: [],
    permission: null
  }),
  async created() {
    this.selection = this.user.user_permissions
    // let res = await axios.get('/api/accounts/user/')
    // console.log(res.data.data)
    // await this.$store.commit("setAuthUser", {
    //   authUser: res.data.data,
    //   isAuthenticated: true,
    // },)
    // console.log((await axios.get('/api/accounts/permission/')).data.data)
    // this.permission = (await axios.get('/api/accounts/permission/')).data.data
  },
  computed: {
    // ...mapState(['authUser']),
    // selection() {
    //   return this.$store.state.authUser.user_permissions
    // }
  },
  watch: {
    user() {
      this.selection = this.user.user_permissions
    }
  },
  methods: {
    async savePermission() {
      let payload = {
        user_id: this.$route.params.id,
        permissions: this.selection
      }
      try {
        await axios.post(`/api/accounts/permission/`, payload)
        this.$store.state.snackbar = {
          text: 'บันทึกการแก้ไขสิทธิสำเร็จ',
          visible: true,
          color: 'success'
        }
        // let res = await axios.get('/api/accounts/user/')
        // await this.$store.commit("setAuthUser", {
        //   authUser: res.data.data,
        //   isAuthenticated: true,
        // },)
      } catch (e) {
        console.error(e)
      }
      // axios.post(`/api/accounts/permission/`, paylaod)
      //     .then(() => {
      //       this.$store.state.snackbar = {
      //         text: 'บันทึกการแก้ไขสิทธิสำเร็จ',
      //         visible: true,
      //         color: 'success'
      //       }
      //     }).catch(e => {
      //   console.error(e)
      // })
    }
  }
}
</script>

<style scoped>

</style>