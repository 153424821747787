<template>
  <div>
    <v-btn depressed @click="showDateDialog=true" block class="d-flex justify-start">
      <v-icon left>mdi-calendar</v-icon>
      {{ dateRangeText }}
    </v-btn>
    
    <v-dialog
      ref="dialog"
      v-model="showDateDialog"
      :return-value.sync="value"
      persistent
      width="290px"
    >
      <v-date-picker
        :value="value"
        @input="update($event)"
        range
      >
        <v-spacer></v-spacer>
        
        <v-btn
          text
          color="primary"
          @click="showDateDialog = false;"
        >
          {{ cancelString }}
        </v-btn>
        <v-btn
          :disabled="value.length === 0"
          text
          color="primary"
          @click="$refs.dialog.save(value);$emit('accept')"
        >
          {{ acceptString }}
        </v-btn>
      </v-date-picker>
    </v-dialog>
  </div>
</template>

<script>
import DateTime from "luxon/src/datetime";

export default {
  props: {
    value: {
      type: Array,
      default: () => ([])
    },
    backwards: {
      type: Number,
      default: 0
    },
    acceptString: {
      type: String,
      default: 'OK'
    },
    cancelString: {
      type: String,
      default: 'Cancel'
    }
  },
  data() {
    return {
      showDateDialog: false,
      // dates: []
    }
  },
  async created() {
    if (this.backwards === 0) {
      this.update([DateTime.now().toFormat('yyyy-LL-dd')])
    } else if (this.backwards < 1) {
      this.update([DateTime.now().minus({ days: this.backwards * 10 }).toFormat('yyyy-LL-dd'), DateTime.now().toFormat('yyyy-LL-dd')])
    } else {
      this.update([DateTime.now().minus({ months: this.backwards }).toFormat('yyyy-LL-dd'), DateTime.now().toFormat('yyyy-LL-dd')])
    }
  },
  computed: {
    dateRangeText() {
      if (this.value === null) {
        return ""
      } else {
        return this.value.map((e) => DateTime.fromFormat(e, 'yyyy-LL-dd').toFormat('dd/LL/yyyy')).sort().join(' - ')
      }
    },
  },
  methods: {
    update(value) {
      // if (value.length === 1) {
      //   value = [...value, ...value]
      // }
      this.$emit('input', value.sort())
    }
  }
}
</script>

<style scoped>

</style>